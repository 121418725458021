import React, {useEffect, useRef} from "react";
import '../css/about.css';

const Julie = () => {
    const vid = useRef(null);
    var foo = document.getElementById("vid");
    vid.currentTime = 30; 

    return (
    <>
    <div className="about-page-background-image">
        <div className="about-padding-color ">  
                <div className="about-box align-middle"> 
                    <h2>Hey Julie</h2> 
                    <video  id="vid" width="500" controls autoPlay="true" ref={vid}>
                        <source src="https://s3.us-east-1.amazonaws.com/capecodbeachesapp.com/S15E5-1.mov" type="video/mp4"/>
                    </video>
                    <h4>
                        <br/>
                        <br/>
                    </h4>

                
                </div>
                <div className="about-box align-middle"> 
                    <h2>Hey Julie</h2> 
                    <video  id="vid" width="500" controls autoPlay="true" ref={vid}>
                        <source src="https://s3.us-east-1.amazonaws.com/capecodbeachesapp.com/S15E5-2.mov" type="video/mp4"/>
                    </video>
                    <h4>
                        <br/>
                        <br/>
                    </h4>

                
                </div>

        </div>
    </div>     
    </>
    );
}
export default Julie;
